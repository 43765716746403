/** @jsx jsx */
import { jsx } from "theme-ui"
//import { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Obfuscate from "react-obfuscate"
import SponorPlaceholder from "../images/sponsor_placeholder.jpg"

const Sponsorships = ({ data }) => {
  /*const [stateExecutive, setstateExecutive] = useState(false)
  const [statePlatinum, setstatePlatinum] = useState(false)
  const [stateGold, setstateGold] = useState(false)
  const [stateSilver, setstateSilver] = useState(false)
  const [stateTalks, setstateTalks] = useState(false)

  useEffect(() => {
    const filterSponsors = () => {
      for (let i = 0; i < data.allWpSponsor.nodes.length; i++) {
        if (data.allWpSponsor.nodes[i].sponsorDetails.sponsorRank === 5) {
          setstateTalks(true)
        } else if (
          data.allWpSponsor.nodes[i].sponsorDetails.sponsorRank === 4
        ) {
          setstateSilver(true)
        } else if (
          data.allWpSponsor.nodes[i].sponsorDetails.sponsorRank === 3
        ) {
          setstateGold(true)
        } else if (
          data.allWpSponsor.nodes[i].sponsorDetails.sponsorRank === 2
        ) {
          setstatePlatinum(true)
        } else if (
          data.allWpSponsor.nodes[i].sponsorDetails.sponsorRank === 1
        ) {
          setstateExecutive(true)
        } else {
          consolallPartner.log("")
        }
      }
    }
    filterSponsors()
  }, [])
  const executive = data.allWpSponsor.nodes.filter(
    sponsor => sponsor.sponsorDetails.sponsorRank === 1
  )
  const platinum = data.allWpSponsor.nodes.filter(
    sponsor => sponsor.sponsorDetails.sponsorRank === 2
  )
  const gold = data.allWpSponsor.nodes.filter(
    sponsor => sponsor.sponsorDetails.sponsorRank === 3
  )
  const silver = data.allWpSponsor.nodes.filter(
    sponsor => sponsor.sponsorDetails.sponsorRank === 4
  )
  const talks = data.allWpSponsor.nodes.filter(
    sponsor => sponsor.sponsorDetails.sponsorRank === 5
  )
  */

  return (
    <Layout>
      <SEO
        title="Sponsors | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xxl" sx={{ paddingTop: `170px` }}>
        <h1>{data.allWpPartner.nodes[0]?.partner.introTitle}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.allWpPartner.nodes[0]?.partner.introText,
          }}
        />
      </div>
      <div sx={{ backgroundColor: `#eef0f3`, padding: `90px 0` }}>
        <div className="grid-container">
          {!!data.allWpPartner.nodes[0]?.partner.leadershipTitle && (
            <h2 className="gap-xl">
              {data.allWpPartner.nodes[0]?.partner.leadershipTitle}
            </h2>
          )}
          <div>
            <div
              sx={{
                display: `grid`,
                gridTemplateColumns: ["12fr", "12fr", "12fr"],
                gridTemplateRows: `repeat(auto)`,
                rowGap: `30px`,
                alignItems: `center`,
              }}
            >
              {data.allWpPartner.nodes[0]?.partner?.leaderships.map(
                allPartners => {
                  return (
                    <div
                      className="partner-group"
                      /* sx={{
                    borderBottom: `2px solid #3a5d7f`,
                    mb: `50px`,
                    pb: `50px`,
                  }} */
                    >
                      {allPartners.partner.map(allPartner => {
                        return (
                          <div id={allPartner.slug}>
                            {/* {allPartner?.sponsorDetails?.hideSponsor === null && ( */}
                            <div
                              /* sx={{
                            mb:
                              allPartners.partner.length === 1 ? `0` : `100px`,
                          }} */
                              className="partner-group-gap"
                            >
                              {/* <div sx={{ display: `flex`, mb: `50px` }}>
                              <div>
                          <h3 sx={{ fontSize: `32px`, mb: `0` }}>{allPartner.title}</h3>
                        </div>
                            </div> */}
                              <div
                                sx={{
                                  display: `grid`,
                                  gridTemplateColumns: [
                                    "12fr",
                                    "12fr",
                                    "4fr 8fr",
                                  ],
                                  gridTemplateRows: `repeat(auto)`,
                                  rowGap: `30px`,
                                  gridColumnGap: `3%`,
                                }}
                                key={allPartner.id}
                                className="sponsors-one"
                              >
                                <div>
                                  <div>
                                    <a
                                      href={
                                        allPartner.sponsorDetails?.sponsorLink
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {allPartner.featuredImage !== null ? (
                                        <Img
                                          sx={{
                                            maxWidth: ["60%", "50%", "70%"],
                                            mb: `3rem`,
                                          }}
                                          fluid={
                                            allPartner.featuredImage?.node
                                              ?.localFile?.childImageSharp
                                              ?.fluid
                                          }
                                        />
                                      ) : (
                                        <img
                                          src={SponorPlaceholder}
                                          alt="Sponsor Placeholder"
                                          sx={{
                                            maxWidth: ["60%", "50%", "70%"],
                                            mb: `3rem`,
                                          }}
                                        />
                                      )}
                                    </a>
                                  </div>
                                  {allPartner.sponsorDetails?.contactInfo ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          allPartner.sponsorDetails
                                            ?.contactInfo,
                                      }}
                                      sx={{ mb: `10px` }}
                                    ></div>
                                  ) : null}
                                  {allPartner.sponsorDetails?.contactEmail !=
                                  null ? (
                                    <div
                                      onClick="#"
                                      onKeyDown="#"
                                      role="button"
                                    >
                                      <Obfuscate
                                        href={`mailto:${allPartner.sponsorDetails?.contactEmail}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <button
                                          sx={{
                                            width: `200px`,
                                            mb: `1rem`,
                                          }}
                                        >
                                          {
                                            allPartner.sponsorDetails
                                              ?.emailButtonLabel
                                          }
                                        </button>
                                      </Obfuscate>
                                    </div>
                                  ) : null}
                                  {allPartner.sponsorDetails?.contactUrl !=
                                  null ? (
                                    <div
                                      onClick="#"
                                      onKeyDown="#"
                                      role="button"
                                    >
                                      <a
                                        href={
                                          allPartner.sponsorDetails?.contactUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <button sx={{ width: `200px` }}>
                                          {
                                            allPartner.sponsorDetails
                                              ?.urlButtonLabel
                                          }
                                        </button>
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                                <div>
                                  <div>
                                    {allPartner.sponsorDetails?.upperImage ||
                                    allPartner.sponsorDetails?.upperVideo !=
                                      null ? (
                                      <div>
                                        {allPartner.sponsorDetails
                                          ?.upperMediaFileType === "Image" ? (
                                          <Img
                                            fluid={
                                              allPartner.sponsorDetails
                                                ?.upperImage?.localFile
                                                ?.childImageSharp?.fluid
                                            }
                                            sx={{ mb: `50px` }}
                                          />
                                        ) : null}{" "}
                                        {allPartner.sponsorDetails
                                          ?.upperMediaFileType === "Video" ? (
                                          <iframe
                                            width="100%"
                                            height="450"
                                            src={
                                              allPartner.sponsorDetails
                                                ?.upperVideo
                                            }
                                            frameBorder="0"
                                            title="video"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            loading="lazy"
                                          ></iframe>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>
                                  {allPartner.sponsorDetails
                                    ?.sponsorDescription ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          allPartner.sponsorDetails
                                            ?.sponsorDescription,
                                      }}
                                      sx={{ mb: `50px` }}
                                    ></div>
                                  ) : null}
                                  {allPartner.sponsorDetails?.lowerImage ||
                                  allPartner.sponsorDetails?.lowerVideoCopy ? (
                                    <div>
                                      {allPartner.sponsorDetails
                                        ?.lowerMediaFileType === "Image" ? (
                                        <Img
                                          fluid={
                                            allPartner.sponsorDetails
                                              ?.lowerImage?.localFile
                                              ?.childImageSharp?.fluid
                                          }
                                          sx={{ mb: `50px` }}
                                        />
                                      ) : null}{" "}
                                      {allPartner.sponsorDetails
                                        ?.lowerMediaFileType === "Video" ? (
                                        <iframe
                                          width="100%"
                                          height="450"
                                          src={
                                            allPartner.sponsorDetails
                                              ?.lowerVideoCopy
                                          }
                                          frameBorder="0"
                                          title="video"
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          loading="lazy"
                                        ></iframe>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            {/*   )} */}
                          </div>
                        )
                      })}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container" sx={{ paddingTop: `90px` }}>
        {!!data.allWpPartner.nodes[0]?.partner.supporterTitle && (
          <h2 className="gap-xl">
            {data.allWpPartner.nodes[0]?.partner.supporterTitle}
          </h2>
        )}
        <div className="gap-xxl">
          <div
            sx={{
              display: `grid`,
              gridTemplateColumns: ["12fr", "12fr", "12fr"],
              gridTemplateRows: `repeat(auto)`,
              rowGap: `30px`,
              alignItems: `center`,
            }}
          >
            {data.allWpPartner.nodes[0]?.partner?.supporters.map(
              allPartners => {
                return (
                  <div
                    className="partner-group"
                    /* sx={{
                    borderBottom: `2px solid #3a5d7f`,
                    mb: `50px`,
                    pb: `50px`,
                  }} */
                  >
                    {allPartners.partner.map(allPartner => {
                      return (
                        <div id={allPartner.slug}>
                          {/* {allPartner?.sponsorDetails?.hideSponsor === null && ( */}
                          <div
                            /* sx={{
                            mb:
                              allPartners.partner.length === 1 ? `0` : `100px`,
                          }} */
                            className="partner-group-gap"
                          >
                            {/* <div sx={{ display: `flex`, mb: `50px` }}>
                              <div>
                          <h3 sx={{ fontSize: `32px`, mb: `0` }}>{allPartner.title}</h3>
                        </div>
                            </div> */}
                            <div
                              sx={{
                                display: `grid`,
                                gridTemplateColumns: [
                                  "12fr",
                                  "12fr",
                                  "4fr 8fr",
                                ],
                                gridTemplateRows: `repeat(auto)`,
                                rowGap: `30px`,
                                gridColumnGap: `3%`,
                              }}
                              key={allPartner.id}
                              className="sponsors-one"
                            >
                              <div>
                                <div>
                                  <a
                                    href={
                                      allPartner.sponsorDetails?.sponsorLink
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {allPartner.featuredImage !== null ? (
                                      <Img
                                        sx={{
                                          maxWidth: ["60%", "50%", "70%"],
                                          mb: `3rem`,
                                        }}
                                        fluid={
                                          allPartner.featuredImage?.node
                                            ?.localFile?.childImageSharp?.fluid
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={SponorPlaceholder}
                                        alt="Sponsor Placeholder"
                                        sx={{
                                          maxWidth: ["60%", "50%", "70%"],
                                          mb: `3rem`,
                                        }}
                                      />
                                    )}
                                  </a>
                                </div>
                                {allPartner.sponsorDetails?.contactInfo ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        allPartner.sponsorDetails?.contactInfo,
                                    }}
                                    sx={{ mb: `10px` }}
                                  ></div>
                                ) : null}
                                {allPartner.sponsorDetails?.contactEmail !=
                                null ? (
                                  <div onClick="#" onKeyDown="#" role="button">
                                    <Obfuscate
                                      href={`mailto:${allPartner.sponsorDetails?.contactEmail}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button
                                        sx={{
                                          width: `200px`,
                                          mb: `1rem`,
                                        }}
                                      >
                                        {
                                          allPartner.sponsorDetails
                                            ?.emailButtonLabel
                                        }
                                      </button>
                                    </Obfuscate>
                                  </div>
                                ) : null}
                                {allPartner.sponsorDetails?.contactUrl !=
                                null ? (
                                  <div onClick="#" onKeyDown="#" role="button">
                                    <a
                                      href={
                                        allPartner.sponsorDetails?.contactUrl
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button sx={{ width: `200px` }}>
                                        {
                                          allPartner.sponsorDetails
                                            ?.urlButtonLabel
                                        }
                                      </button>
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div>
                                  {allPartner.sponsorDetails?.upperImage ||
                                  allPartner.sponsorDetails?.upperVideo !=
                                    null ? (
                                    <div>
                                      {allPartner.sponsorDetails
                                        ?.upperMediaFileType === "Image" ? (
                                        <Img
                                          fluid={
                                            allPartner.sponsorDetails
                                              ?.upperImage?.localFile
                                              ?.childImageSharp?.fluid
                                          }
                                          sx={{ mb: `50px` }}
                                        />
                                      ) : null}{" "}
                                      {allPartner.sponsorDetails
                                        ?.upperMediaFileType === "Video" ? (
                                        <iframe
                                          width="100%"
                                          height="450"
                                          src={
                                            allPartner.sponsorDetails
                                              ?.upperVideo
                                          }
                                          frameBorder="0"
                                          title="video"
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          loading="lazy"
                                        ></iframe>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                                {allPartner.sponsorDetails
                                  ?.sponsorDescription ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        allPartner.sponsorDetails
                                          ?.sponsorDescription,
                                    }}
                                    sx={{ mb: `50px` }}
                                  ></div>
                                ) : null}
                                {allPartner.sponsorDetails?.lowerImage ||
                                allPartner.sponsorDetails?.lowerVideoCopy ? (
                                  <div>
                                    {allPartner.sponsorDetails
                                      ?.lowerMediaFileType === "Image" ? (
                                      <Img
                                        fluid={
                                          allPartner.sponsorDetails?.lowerImage
                                            ?.localFile?.childImageSharp?.fluid
                                        }
                                        sx={{ mb: `50px` }}
                                      />
                                    ) : null}{" "}
                                    {allPartner.sponsorDetails
                                      ?.lowerMediaFileType === "Video" ? (
                                      <iframe
                                        width="100%"
                                        height="450"
                                        src={
                                          allPartner.sponsorDetails
                                            ?.lowerVideoCopy
                                        }
                                        frameBorder="0"
                                        title="video"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        loading="lazy"
                                      ></iframe>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/*   )} */}
                        </div>
                      )
                    })}
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpPartner(sort: { order: DESC, fields: slug }) {
      nodes {
        partner {
          introTitle
          introText
          leadershipTitle
          leaderships {
            partner {
              ... on WpSponsor {
                id
                slug
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
                sponsorDetails {
                  hideSponsor
                  sponsorLink
                  sponsorDescription
                  contactEmail
                  upperImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  lowerImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  lowerMediaFileType
                  lowerVideoCopy
                  upperMediaFileType
                  upperVideo
                  contactEmail
                  contactUrl
                  emailButtonLabel
                  urlButtonLabel
                  contactInfo
                  buttonsCheckbox
                }
              }
            }
          }
          supporterTitle
          supporters {
            partner {
              ... on WpSponsor {
                id
                slug
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
                sponsorDetails {
                  hideSponsor
                  sponsorLink
                  sponsorDescription
                  contactEmail
                  upperImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  lowerImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  lowerMediaFileType
                  lowerVideoCopy
                  upperMediaFileType
                  upperVideo
                  contactEmail
                  contactUrl
                  emailButtonLabel
                  urlButtonLabel
                  contactInfo
                  buttonsCheckbox
                }
              }
            }
          }
        }
      }
    }
    allWpSponsor(sort: { order: ASC, fields: slug }) {
      nodes {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        sponsorDetails {
          hideSponsor
          sponsorLink
          sponsorDescription
          contactEmail
          upperImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          lowerImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          lowerMediaFileType
          lowerVideoCopy
          upperMediaFileType
          upperVideo
          contactEmail
          contactUrl
          emailButtonLabel
          urlButtonLabel
          contactInfo
          buttonsCheckbox
        }
        id
        title
        slug
      }
    }
  }
`

export default Sponsorships
